@import "~bootstrap/scss/mixins";

body {
   padding-top: 80px;
}
.starter-template {
    padding: 3rem 1.5rem;
    text-align: center;
}

// max-width generator
$maximum-width: 300;
@for $i from 1 through $maximum-width {
  .max-length-#{$i} {
    max-width: #{$i}px;
  }
}
